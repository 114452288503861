import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { useUID } from 'react-uid';
import cn from 'classnames';

import styles from './RadioField.module.scss';

type RadioFieldProps = {
  name: string;
  label: string | ReactNode;
  className?: string;
} & Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'type' | 'name' | 'id' | 'className'
>;

const RadioField = forwardRef<HTMLInputElement, RadioFieldProps>(
  ({ label, className, ...props }, ref) => {
    const uid = useUID();

    return (
      <div className={cn('flex items-center', className)}>
        <input
          {...props}
          ref={ref}
          id={`radioField-${uid}-input`}
          className={styles.input}
          type="radio"
        />
        <label htmlFor={`radioField-${uid}-input`} className="ml-3 text-base">
          {label}
        </label>
      </div>
    );
  }
);

export default RadioField;
