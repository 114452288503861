import { SelectFieldOption } from '../components/common';

export const HEADER_HEIGHT = 64; // 4.0 REM

export const ERROR_MESSAGE_GENERIC =
  'Oops, something went wrong, please try again later';
export const ERROR_VALIDATION_MISSING_PAYLOAD = 'Missing request payload';
export const ERROR_UNDEFINED_ENVS =
  'Required environmental variables are undefined';
export const ERROR_INCORRECT_HTTP_METHOD = 'Incorrect API method';
export const ERROR_VALIDATION_MISSING_QUERY_PARAM = 'Missing query parameter';
export const ERROR_DELETING_LAST_CREDIT_CARD =
  "You can't remove your last payment method";
export const ERROR_MISSING_MODIFIERS = 'Missing modifiers in modifier group';
export const ERROR_RESTAURANT_SHARE =
  'Sorry, we were unable to share this restaurant because of an unknown error. Please try again later.';

export const SEARCH_TYPE_OPTIONS: SelectFieldOption<string>[] = [
  {
    label: 'Pickup',
    value: 'pickup',
  },
  {
    label: 'Delivery',
    value: 'delivery',
  },
];

export const DEFAULT_TIMEZONE = 'America/New_York';

export const NAVIGATION_ITEMS: { label: string; href: string }[] = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Restaurants',
    href: '/restaurants',
  },
  {
    label: 'About us',
    href: '/about-us',
  },
  {
    label: 'Contact us',
    href: '/contact-us',
  },
];
