import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  HTMLAttributes,
  ReactNode,
} from 'react';
import cn from 'classnames';

import styles from './Button.module.scss';

type ButtonSizes = 'small' | 'medium' | 'big' | 'fluid';

export type ButtonCommonProps = {
  children: ReactNode;
} & Pick<
  HTMLAttributes<HTMLAnchorElement | HTMLButtonElement>,
  'className' | 'onClick' | 'tabIndex'
>;

type ButtonVariants =
  | {
      kind?: 'primary';
      size?: ButtonSizes;
      filled?: boolean;
    }
  | {
      kind: 'secondary';
      size: Extract<ButtonSizes, 'small'>;
      filled: boolean;
    }
  | {
      kind?: never;
      size?: never;
      filled?: never;
    };

export type ButtonAsAnchorProps = {
  as?: 'a';
} & Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'href' | 'rel' | 'target'>;

export type ButtonAsButtonProps = {
  as?: 'button';
} & Pick<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'autoFocus' | 'type' | 'disabled'
>;

export type ButtonProps = ButtonCommonProps &
  ButtonVariants &
  (ButtonAsAnchorProps | ButtonAsButtonProps);

const Button = React.forwardRef<
  HTMLAnchorElement & HTMLButtonElement,
  ButtonProps
>(
  (
    {
      as: Component = 'button',
      children,
      kind = 'primary',
      size = 'big',
      filled = true,
      ...props
    },
    ref
  ) => {
    return (
      <Component
        ref={ref}
        {...props}
        className={cn(
          'flex',
          styles.base,
          styles[kind],
          styles[size],
          {
            [styles.filled]: filled,
          },
          props?.className
        )}
      >
        {children}
      </Component>
    );
  }
);

export default Button;
