import jwtDecode from 'jwt-decode';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import type { AppProps } from 'next/app';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/node';
import Router from 'next/router';

import { API } from '../api';
import { ERROR_MESSAGE_GENERIC } from '../constants/common';
import { AuthContextValue, DecodedAccessToken } from '../types/auth';
import { destroyAccessTokenCookie, getAccessTokenCookie } from './cookies';
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  DEPLOYMENT_URL,
} from './envs';

export const composeSocialAuthHref = (
  provider: 'facebook' | 'google-oauth2',
  nextUrl: string | null
) =>
  `${AUTH0_DOMAIN}/authorize?response_type=code&client_id=${AUTH0_CLIENT_ID}&connection=${provider}&redirect_uri=${
    DEPLOYMENT_URL + '/callback'
  }&audience=${AUTH0_AUDIENCE}${nextUrl !== null ? `&state=${nextUrl}` : ''}`;

export const signOutUser = (expiredSession = false) => {
  try {
    destroyAccessTokenCookie();
    Router.push(expiredSession ? '/sign-in?expiredSession=true' : '/');

    if (!expiredSession) {
      toast.success('You have been signed out successfully.');
    }
  } catch (e) {
    toast.error(ERROR_MESSAGE_GENERIC);
    Sentry.captureException(e);
  }
};

export const composeAuthContextValue = (
  pageProps: AppProps['pageProps']
): AuthContextValue => {
  const accessToken = pageProps.accessToken;

  return {
    isAuthenticated: !!accessToken,
    accessToken,
    userProfile: pageProps.userProfile,
    signOut: signOutUser,
  };
};

export const isAccessTokenExpired = (accessToken: string): boolean => {
  try {
    const decodedToken = jwtDecode<DecodedAccessToken>(accessToken);

    return decodedToken.exp < Date.now() / 1000;
  } catch (e) {
    Sentry.captureException(e);
    return true;
  }
};

export const withAuth = <P extends Record<string, unknown>>(
  isAuthenticationRequired = false,
  getServerSideProps?: GetServerSideProps<P>
) => async (context: GetServerSidePropsContext) => {
  const accessToken = getAccessTokenCookie(context);
  let userProfile = null;

  // Authorization check
  if (isAuthenticationRequired && !accessToken) {
    const pathname = context.req.url;

    return {
      redirect: {
        destination: `/sign-in${
          typeof pathname === 'string' ? '?nextUrl=' + pathname : ''
        }`,
        permanent: false,
      },
    };
  }

  if (accessToken !== undefined) {
    // JWT expiration check
    if (isAccessTokenExpired(accessToken)) {
      // TODO: Implement silent token refresh
      destroyAccessTokenCookie(context);

      return {
        redirect: {
          destination: '/sign-in?expiredSession=true',
          permanent: false,
        },
      };
    }

    // Fetch user profile
    await API.get('/user-profile', {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    })
      .then(({ data }) => {
        userProfile = data;
      })
      .catch((e) => Sentry.captureException(e));
  }

  if (typeof getServerSideProps === 'function') {
    const result = await getServerSideProps(context);

    return {
      ...result,
      props: {
        accessToken: accessToken ?? null,
        userProfile: userProfile ?? null,
        // TODO: Find a better way to type this
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...result?.props,
      },
    };
  }

  return {
    props: {
      accessToken: accessToken ?? null,
      userProfile: userProfile ?? null,
    },
  };
};
