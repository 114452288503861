import * as Sentry from '@sentry/node';
import { toast } from 'react-toastify';

import { Address } from '../types/auth';
import { GooglePlacesDetailAddressComponent } from '../types/services';
import { ERROR_MESSAGE_GENERIC } from '../constants/common';
import { APIAddress } from '../types/common';
import { getPlacesDetail } from '../api/methods/services';
import { ERROR_GOOGLE_PLACES_DETAIL } from '../constants/services';
import { COMMIT_SHA, NODE_ENV, SENTRY_DSN } from './envs';

export const initSentry = () => {
  if (SENTRY_DSN) {
    Sentry.init({
      enabled: NODE_ENV === 'production',
      dsn: SENTRY_DSN,
      release: COMMIT_SHA ?? 'unknown',
    });
  }
};

export const parseGooglePlacesAddressComponents = (
  components: GooglePlacesDetailAddressComponent[]
):
  | {
      error: true;
      message: string;
    }
  | {
      error: false;
      address: Omit<
        APIAddress,
        'line2' | 'latitude' | 'longitude' | 'googlePlaceId'
      >;
    } => {
  if (!components || !components.length) {
    return {
      error: true,
      message: ERROR_MESSAGE_GENERIC,
    };
  }

  let streetNumber = '';
  let route = '';
  let locality = '';
  let sublocality = '';
  let state = '';
  let postalCode = '';

  components.forEach(({ long_name, short_name, types }) => {
    if (types.includes('street_number')) {
      streetNumber = long_name;
      return;
    }

    if (types.includes('route')) {
      route = long_name;
      return;
    }

    if (types.includes('locality')) {
      locality = long_name;
      return;
    }

    if (types.includes('sublocality')) {
      sublocality = long_name;
      return;
    }

    if (types.includes('administrative_area_level_1')) {
      state = short_name;
      return;
    }

    if (types.includes('postal_code')) {
      postalCode = long_name;
    }
  });

  if (!locality && !sublocality) {
    return {
      error: true,
      message: ERROR_MESSAGE_GENERIC,
    };
  }

  /*  if (!streetNumber || !route || !state || !postalCode) {
    return {
      error: true,
      message: ERROR_ADDRESS_TOO_GENERIC,
    };
  }*/

  return {
    error: false,
    address: {
      line1: `${streetNumber} ${route}`,
      city: locality || sublocality,
      state: state,
      zip: postalCode,
    },
  };
};

export const concatAddress = (address: Partial<Address>): string => {
  const { line1, city, state, zip } = address;

  return `${line1}, ${city}, ${state} ${zip}`;
};

/**
 * Gets Google Place detail and transforms it to the format required by API
 *
 * @param googlePlaceId ID of the Google Place for which to obtain its address
 */
export const getAddressFromGooglePlaceId = async (
  googlePlaceId: string
): Promise<APIAddress | null> => {
  return getPlacesDetail(googlePlaceId).then(async ({ data }) => {
    if (
      data?.status !== 'OK' ||
      data?.result?.address_components === undefined
    ) {
      toast.error(ERROR_GOOGLE_PLACES_DETAIL);
      return null;
    }

    const parsedAddress = parseGooglePlacesAddressComponents(
      data.result.address_components
    );

    if (parsedAddress?.error) {
      toast.error(parsedAddress.message);
      return null;
    }

    return {
      ...parsedAddress.address,
      latitude: data.result.geometry.location.lat,
      longitude: data.result.geometry.location.lng,
      googlePlaceId,
    };
  });
};
