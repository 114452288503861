import { filter, flatten, map, sum } from 'ramda';

import { OrderTotals } from '../../types/orders';
import { StoreMenuProduct } from '../../types/stores';
import { pipeline } from './datetime';

export const computeOrderItemBasePrice = (item: StoreMenuProduct): number => {
  return computeOrderItemPrice(item) - computeOrderItemEmbeddedTaxesTotal(item);
};

export const computeOrderItemPrice = (item: StoreMenuProduct): number => {
  return (
    (item.price || 0) +
    pipeline(
      item.modifierGroups || [],
      map((mg) => mg.groupPrice || 0),
      sum
    ) +
    pipeline(
      item.modifierGroups || [],
      map((mg) => mg.modifiers || []),
      flatten,
      map((m) => (m.quantity || 1) * (m.price || 0)),
      sum
    )
  );
};

export const computeOrderItemSubtotal = (item: StoreMenuProduct): number => {
  // todo item level pricings
  return computeOrderItemBasePrice(item);
};

export const computeOrderItemPricingsTotal = (): number => {
  return 0;
};

export const computeOrderItemTaxesTotal = (item: StoreMenuProduct): number => {
  return (
    computeOrderItemEmbeddedTaxesTotal(item) +
    computeOrderItemNotEmbeddedTaxesTotal(item)
  );
};

export const computeOrderItemTotal = (item: StoreMenuProduct): number => {
  return computeOrderItemSubtotal(item) + computeOrderItemTaxesTotal(item);
};

export const computeOrderItemEmbeddedTaxesTotal = (
  item: StoreMenuProduct
): number => {
  const embeddedTaxes = pipeline(
    item.taxes || [],
    filter((t: StoreMenuProduct['taxes'][0]) => t.isEmbedded),
    map((t) => t.percentage || 0),
    sum
  );
  const price = computeOrderItemPrice(item);
  return price - computeBaseAmountFromTotal(price, embeddedTaxes);
};

export const computeOrderItemNotEmbeddedTaxesTotal = (
  item: StoreMenuProduct
): number => {
  const notEmbeddedTaxes = pipeline(
    item.taxes || [],
    filter((t: StoreMenuProduct['taxes'][0]) => !t.isEmbedded),
    map((t) => t.percentage || 0),
    sum
  );
  const base = computeOrderItemBasePrice(item);
  return computePercentageValue(base, notEmbeddedTaxes);
};

export const computeBaseAmountFromTotal = (
  total: number,
  percentage: number
): number => {
  return Math.round((total * 100 * 1000) / (100 * 1000 + percentage));
};

export const computePercentageValue = (
  base: number,
  percentage: number
): number => {
  return Math.round((percentage * base) / (100 * 1000));
};

export const computeOrderItemTotals = (
  item: StoreMenuProduct
): OrderTotals => ({
  quantity: item.quantity,
  price: computeOrderItemPrice(item),
  basePrice: computeOrderItemBasePrice(item),
  subtotal: computeOrderItemSubtotal(item),
  pricingsTotal: computeOrderItemPricingsTotal(),
  taxesTotal: computeOrderItemTaxesTotal(item),
  total: computeOrderItemTotal(item),
});
