export const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID;
export const AUTH0_CLIENT_SECRET = process.env.AUTH0_CLIENT_SECRET;
export const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN;
export const AUTH0_AUDIENCE = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE;

export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
export const COMMIT_SHA = process.env.VERCEL_GIT_COMMIT_SHA;
export const SENTRY_PROJECT = process.env.SENTRY_PROJECT;
export const SENTRY_ORG = process.env.SENTRY_ORG;

export const CARDCONNECT_URL = process.env.NEXT_PUBLIC_CARDCONNECT_URL;

export const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;
export const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const DEPLOYMENT_URL = process.env.NEXT_PUBLIC_DEPLOYMENT_URL;

export const NODE_ENV = process.env.NODE_ENV;
