import React, { createContext, useCallback, useState } from 'react';

import {
  GlobalContextInterface,
  GlobalContextModal,
  GlobalModifiersModalContext,
  GlobalOrderScheduleModalContext,
} from '../../../types/common';

export const GLOBAL_CONTEXT_DEFAULTS: GlobalContextInterface = {
  isModalOpen: () => false,
  openModal: () => null,
  closeModal: () => null,
  modifiersModalContext: null,
  setModifiersModalContext: () => null,
  orderScheduleModalContext: null,
  setOrderScheduleModalContext: () => null,
};

export const GlobalContext = createContext<GlobalContextInterface>(
  GLOBAL_CONTEXT_DEFAULTS
);

const GlobalContextProvider = ({ children }) => {
  const [isJoinUsModalOpen, setJoinUsModalOpen] = useState(false);
  const [isModifiersModalOpen, setModifiersModalOpen] = useState(false);
  const [isClearOrderModalOpen, setClearOrderModalOpen] = useState(false);
  const [isOrderScheduleModalOpen, setOrderScheduleModalOpen] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);

  const [
    modifiersModalContext,
    setModifiersModalContext,
  ] = useState<GlobalModifiersModalContext | null>(null);
  const [
    orderScheduleModalContext,
    setOrderScheduleModalContext,
  ] = useState<GlobalOrderScheduleModalContext | null>(null);

  const isModalOpen = useCallback(
    (modal: GlobalContextModal) => {
      switch (modal) {
        case 'join_us': {
          return isJoinUsModalOpen;
        }

        case 'modifiers': {
          return isModifiersModalOpen;
        }

        case 'clear_order': {
          return isClearOrderModalOpen;
        }

        case 'order_schedule': {
          return isOrderScheduleModalOpen;
        }

        case 'share': {
          return isShareModalOpen;
        }
      }
    },
    [
      isJoinUsModalOpen,
      isModifiersModalOpen,
      isClearOrderModalOpen,
      isOrderScheduleModalOpen,
      isShareModalOpen,
    ]
  );

  const openModal = useCallback(
    (modal: GlobalContextModal) => {
      switch (modal) {
        case 'join_us': {
          setJoinUsModalOpen(true);
          return;
        }

        case 'modifiers': {
          setModifiersModalOpen(true);
          return;
        }

        case 'clear_order': {
          setClearOrderModalOpen(true);
          return;
        }

        case 'order_schedule': {
          setOrderScheduleModalOpen(true);
          return;
        }

        case 'share': {
          setShareModalOpen(true);
          return;
        }
      }
    },
    [
      setJoinUsModalOpen,
      setModifiersModalOpen,
      setClearOrderModalOpen,
      setOrderScheduleModalOpen,
      setShareModalOpen,
    ]
  );

  const closeModal = useCallback(
    (modal: GlobalContextModal) => {
      switch (modal) {
        case 'join_us': {
          setJoinUsModalOpen(false);
          return;
        }

        case 'modifiers': {
          setModifiersModalOpen(false);
          return;
        }

        case 'clear_order': {
          setClearOrderModalOpen(false);
          return;
        }

        case 'order_schedule': {
          setOrderScheduleModalOpen(false);
          return;
        }

        case 'share': {
          setShareModalOpen(false);
          return;
        }
      }
    },
    [
      setJoinUsModalOpen,
      setModifiersModalOpen,
      setClearOrderModalOpen,
      setOrderScheduleModalOpen,
      setShareModalOpen,
    ]
  );

  return (
    <GlobalContext.Provider
      value={{
        isModalOpen,
        openModal,
        closeModal,
        modifiersModalContext,
        setModifiersModalContext,
        orderScheduleModalContext,
        setOrderScheduleModalContext,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
