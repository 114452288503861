import React, { ReactText, useEffect } from 'react';
import type { AppProps } from 'next/app';
import { UIDReset } from 'react-uid';
import {
  ToastContainer,
  Slide as SlideTransition,
  toast,
} from 'react-toastify';
import Head from 'next/head';
import dynamic from 'next/dynamic';

import { initSentry } from '../helpers/services';
import { AuthContextProvider } from '../hooks/auth';
import { composeAuthContextValue } from '../helpers/auth';
import { ERROR_UNABLE_TO_FETCH_PROFILE } from '../constants/auth';
import { GlobalContextProvider } from '../components/common';
import OrderContextProvider from '../components/orders/order-context-provider/OrderContextProvider';

import '@reach/tabs/styles.css';
import '@reach/dialog/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-image-lightbox/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/main.scss';

const DynamicMobileBottomNavigation = dynamic(
  () =>
    import(
      '../components/layout/mobile-bottom-navigation/MobileBottomNavigation'
    ),
  {
    ssr: false,
  }
);

initSentry();

const CustomApp = ({ Component, pageProps }: AppProps) => {
  const errorToastId = React.useRef<ReactText | null>(null);

  const authContextValue = composeAuthContextValue(pageProps);

  useEffect(() => {
    if (authContextValue.isAuthenticated && !authContextValue.userProfile) {
      if (errorToastId.current !== null) {
        toast.dismiss(errorToastId.current);
      }

      errorToastId.current = toast.error(ERROR_UNABLE_TO_FETCH_PROFILE);
    }
  }, [authContextValue]);

  return (
    <>
      <Head>
        <title>Ktown</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=5.0, minimum-scale=1.0"
        />
      </Head>

      <AuthContextProvider value={authContextValue}>
        <GlobalContextProvider>
          <OrderContextProvider>
            <UIDReset>
              <Component {...pageProps} />

              <ToastContainer
                position="bottom-right"
                transition={SlideTransition}
                limit={3}
              />

              <DynamicMobileBottomNavigation />
            </UIDReset>
          </OrderContextProvider>
        </GlobalContextProvider>
      </AuthContextProvider>
    </>
  );
};

export default CustomApp;
