import React, { ButtonHTMLAttributes, ReactNode } from 'react';
import cn from 'classnames';

import { Icon, IconProps } from '../';

import styles from './TransparentButton.module.scss';

type TransparentButtonIconProps =
  | {
      iconLeft?: never;
      iconLeftAlt?: never;
      iconLeftClassName?: never;
      iconRight?: never;
      iconRightAlt?: never;
      iconRightClassName?: never;
    }
  | {
      iconLeft: IconProps['icon'];
      iconLeftAlt: IconProps['alt'];
      iconLeftClassName?: IconProps['className'];
      iconRight?: never;
      iconRightAlt?: never;
      iconRightClassName?: never;
    }
  | {
      iconRight: IconProps['icon'];
      iconRightAlt: IconProps['alt'];
      iconRightClassName?: IconProps['className'];
      iconLeft?: never;
      iconLeftAlt?: never;
      iconLeftClassName?: never;
    };

export type TransparentButtonProps = {
  kind?: 'primary' | 'secondary';
  children: ReactNode;
  dataTestId?: string;
} & TransparentButtonIconProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

const TransparentButton: React.FC<TransparentButtonProps> = ({
  kind = 'primary',
  iconLeft,
  iconLeftAlt,
  iconLeftClassName,
  iconRight,
  iconRightAlt,
  iconRightClassName,
  type = 'button',
  className,
  dataTestId,
  children,
  ...props
}) => (
  <button
    {...props}
    type={type}
    className={cn(
      styles.base,
      {
        'text-red': kind === 'primary',
        'text-brown-light': kind === 'secondary',
      },
      className
    )}
    data-testid={dataTestId ?? 'transparent-button-main'}
  >
    {iconLeft && (
      <Icon
        icon={iconLeft}
        alt={iconLeftAlt ?? 'Icon'}
        className={cn(
          {
            'mr-2': children !== undefined,
          },
          iconLeftClassName
        )}
      />
    )}

    {children}

    {!!iconRight && (
      <Icon
        icon={iconRight}
        alt={iconRightAlt ?? 'Icon'}
        className={cn(
          {
            'ml-2': children !== undefined,
          },
          iconRightClassName
        )}
      />
    )}
  </button>
);

export default TransparentButton;
