import React, { memo } from 'react';
import ContentLoader from 'react-content-loader';

import { useUID } from 'react-uid';
import twConfig from '../../../tailwind.config.js';

const BACKGROUND_COLOR = twConfig.theme.colors.gray.lighter;
const FOREGROUND_COLOR = twConfig.theme.colors.gray.light;

export const PersonalProfileLoader = memo(() => {
  const uid = useUID();

  return (
    <ContentLoader
      viewBox="0 0 400 480"
      height={480}
      width={400}
      backgroundColor={BACKGROUND_COLOR}
      foregroundColor={FOREGROUND_COLOR}
      style={{ width: '100%' }}
      uniqueKey={uid}
    >
      <rect x="115" y="35" rx="4" ry="4" width="254" height="6" />
      <rect x="115" y="55" rx="3" ry="3" width="185" height="7" />
      <circle cx="48" cy="48" r="48" />

      <rect x="0" y="130" rx="0" ry="0" width="258" height="32" />
      <rect x="0" y="172" rx="0" ry="0" width="465" height="32" />
      <rect x="0" y="214" rx="0" ry="0" width="749" height="32" />
    </ContentLoader>
  );
});

export const OrdersProfileLoader = memo(() => {
  const uid = useUID();

  return (
    <ContentLoader
      viewBox="0 0 800 500"
      width={800}
      height={500}
      backgroundColor={BACKGROUND_COLOR}
      foregroundColor={FOREGROUND_COLOR}
      style={{ width: '100%' }}
      uniqueKey={uid}
    >
      {/*<rect x="600" y="0" rx="4" ry="4" width="200" height="40" /> -- Sorting*/}
      <rect x="0" y="0" rx="4" ry="4" width="800" height="220" />
      <rect x="0" y="240" rx="4" ry="4" width="800" height="220" />
    </ContentLoader>
  );
});

export const HeaderUserLoader = memo(() => {
  const uid = useUID();

  return (
    <ContentLoader
      viewBox="0 0 100 40"
      height={40}
      width={100}
      backgroundColor={BACKGROUND_COLOR}
      foregroundColor={FOREGROUND_COLOR}
      uniqueKey={uid}
    >
      <rect x="50" y="10" rx="4" ry="4" width="50" height="5" />
      <rect x="50" y="25" rx="3" ry="3" width="50" height="5" />
      <circle cx="20" cy="20" r="18" />
    </ContentLoader>
  );
});

export const ModalLoader = memo(() => {
  const uid = useUID();

  return (
    <ContentLoader
      viewBox="0 0 600 300"
      width={600}
      height={300}
      backgroundColor={BACKGROUND_COLOR}
      foregroundColor={FOREGROUND_COLOR}
      uniqueKey={uid}
    >
      <rect x="20" y="20" rx="4" ry="4" width="400" height="50" />
      <rect x="20" y="90" rx="4" ry="4" width="600" height="15" />
      <rect x="20" y="115" rx="4" ry="4" width="600" height="15" />
      <rect x="20" y="140" rx="4" ry="4" width="600" height="15" />
    </ContentLoader>
  );
});

export const ProductLoader = memo(() => {
  const uid = useUID();

  return (
    <ContentLoader
      className="bg-white rounded-md shadow-sm overflow-hidden"
      viewBox="0 0 500 310"
      height={310}
      width={500}
      backgroundColor={BACKGROUND_COLOR}
      foregroundColor={FOREGROUND_COLOR}
      style={{ width: '100%' }}
      uniqueKey={uid}
    >
      <rect x="0" y="0" width="500" height="167" />
      <rect x="17" y="184" width="120" height="12" rx="4" />
      <rect x="17" y="208" width="320" height="12" rx="4" />
      <rect x="17" y="226" width="320" height="12" rx="4" />
      <rect x="17" y="244" width="50" height="12" rx="4" />
    </ContentLoader>
  );
});
