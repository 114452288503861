import {
  any,
  flatten,
  groupBy,
  keys,
  map,
  match,
  toPairs,
  reduce,
  reject,
  isNil,
} from 'ramda';
import {
  addDays,
  format,
  getDate,
  getMonth,
  getYear,
  isAfter,
  isBefore,
  parse,
  set,
} from 'date-fns';
import { format as tzFormat, utcToZonedTime } from 'date-fns-tz';

export type TimeSpan = string;
export type IsoDateTime = string;

export const pipeline = (argument: any, ...inputs: Array<any>) =>
  reduce((value, func) => func(value), argument, inputs);

const compact = (argument: any) => reject(isNil, argument);

export type ParsedTimeSpan = {
  dayOfWeek?: string;
  day?: number;
  month?: number;
  year?: number;
  ranges?: TimeRange[];
  always?: boolean;
  never?: boolean;
};

export type TimeRange = {
  start: string;
  end: string;
  isFromPreviousDay?: boolean;
};

const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

/**
 * Useful for checking whether the business (or other entity with HOO TimeSpans) is open/closed.
 */
export const isDateTimeWithinTimeSpans = (
  spans: TimeSpan[],
  current: IsoDateTime,
  timezone
): boolean => {
  if (spans.length === 0) {
    return false;
  }

  const currentDate = utcToZonedTime(current, timezone);
  const parsedSpans = sortParsedTimeSpans(parseTimeSpans(spans, true));

  for (const span of parsedSpans) {
    // Is it always closed/opened without specific date?
    if (!span.dayOfWeek && !span.year && !span.month && !span.day) {
      if (span.never) {
        return false;
      } else if (span.always) {
        return true;
      }
    }

    // Is within span day?
    if (isParsedTimeSpanRelatedToDate(span, current, timezone)) {
      // First check eventual ranges from previous day
      const rangeFromPrevDay = (span.ranges || []).find(
        (r) => !!r.isFromPreviousDay
      );
      if (
        rangeFromPrevDay &&
        isWithinParsedSpanRange(currentDate, rangeFromPrevDay)
      ) {
        return true;
      }

      if (span.never) {
        return false;
      }

      // Is always opened or within span time range?
      return (
        span.always ||
        (span.ranges || []).some((range) =>
          isWithinParsedSpanRange(currentDate, range)
        )
      );
    }
  }

  return false;
};

export const isWithinParsedSpanRange = (date: Date, range: TimeRange) => {
  const start = parse(range.start, 'H:mm', date);
  const end = parse(range.end, 'H:mm', date);

  return isAfter(date, start) && isBefore(date, end);
};

/**
 * Useful for displaying formatted HOO times on frontend. eg:
 * Monday      09:00 am - 10:00 pm
 *             15:00 am - 22:00 pm
 * Tuesday     'Whole day'
 * Wednesday   'Closed'
 * etc.
 */
export type FormattedHOO = {
  dayOfWeek:
    | 'Monday'
    | 'Tuesday'
    | 'Wednesday'
    | 'Thursday'
    | 'Friday'
    | 'Saturday'
    | 'Sunday';
  isToday: boolean;
  isAlways?: boolean;
  ranges: TimeRange[];
};

export const getFormattedHOO = (
  now: IsoDateTime,
  hooTimeSpans: TimeSpan[],
  timezone: string,
  timeFormat = 'hh:mm a'
): FormattedHOO[] => {
  const map: Record<string, FormattedHOO['dayOfWeek']> = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
  };

  const tz = timezone;
  const currentLocalDate = utcToZonedTime(now, tz);
  const parsedSpans: ParsedTimeSpan[] = parseTimeSpans(hooTimeSpans, false);
  return keys(map).map((dayOfWeek) => {
    const dayName = map[dayOfWeek];
    const relatedSpan = parsedSpans.find((p) => p.dayOfWeek === dayOfWeek);
    const isToday =
      tzFormat(currentLocalDate, 'EEE', { timeZone: tz }) === dayOfWeek;

    if (relatedSpan && !relatedSpan.never) {
      return {
        dayOfWeek: dayName,
        isToday: isToday,
        isAlways: relatedSpan.always,
        ranges: (relatedSpan.ranges || []).map((r) => {
          const startLocal = parse(r.start, 'H:mm', currentLocalDate);
          const endLocal = parse(r.end, 'H:mm', currentLocalDate);

          return {
            start: tzFormat(startLocal, timeFormat, { timeZone: tz }),
            end: tzFormat(endLocal, timeFormat, { timeZone: tz }),
          };
        }),
      };
    } else {
      return {
        dayOfWeek: dayName,
        isToday: isToday,
        ranges: [],
      };
    }
  });
};

/**
 * Useful for parsing the original string TimeSpans[] into object structure
 */
export const parseTimeSpans = (
  input: TimeSpan[],
  splitAfterMidnight = true
): ParsedTimeSpan[] => {
  return pipeline(
    input,
    map((s) => {
      if (s === '*') {
        return {
          always: true,
        };
      } else if (s === 'x') {
        return {
          never: true,
        };
      }

      const parts = s.split(' ').filter((p) => p);
      const datePart = parts[0];
      const rangeParts = parts.slice(1);
      const parsedDatePart = constructParsedTimeSpanDatePart(datePart);

      return pipeline(
        rangeParts,
        map((r) => {
          if (r === '*') {
            return [
              {
                ...parsedDatePart,
                always: true,
              },
            ];
          } else if (r === 'x') {
            return [
              {
                ...parsedDatePart,
                never: true,
              },
            ];
          }

          const rr = r.split('-');
          const start = parse(rr[0], 'H:mm', new Date());
          const end = parse(rr[1], 'H:mm', new Date());

          if (splitAfterMidnight && isAfter(start, end)) {
            return [
              {
                ...parsedDatePart,
                ranges: [
                  {
                    start: rr[0],
                    end: '0:00',
                  },
                ],
              },
              {
                ...getTimeSpanForNextDay(parsedDatePart),
                ranges: [
                  {
                    start: '0:00',
                    end: rr[1],
                    isFromPreviousDay: true,
                  },
                ],
              },
            ];
          } else {
            return [
              {
                ...parsedDatePart,
                ranges: [
                  {
                    start: rr[0],
                    end: rr[1],
                  },
                ],
              },
            ];
          }
        }),
        flatten
      );
    }),
    flatten,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    groupSameParsedSpanDates
  );
};

export const groupSameParsedSpanDates = (
  spans: ParsedTimeSpan[]
): ParsedTimeSpan[] => {
  return pipeline(
    spans,
    groupBy((s: ParsedTimeSpan) =>
      compact([s.dayOfWeek, s.day, s.month, s.year]).join()
    ),
    toPairs,
    map(([, props]: [string, ParsedTimeSpan[]]) => props),
    map((p: ParsedTimeSpan[]) => {
      const ranges = flatten<TimeRange>(p.map((pa) => pa.ranges || []));
      const always = any((i) => i.always == true, p);
      const never = any((i) => i.never == true, p);

      return {
        dayOfWeek: p[0].dayOfWeek,
        year: p[0].year,
        month: p[0].month,
        day: p[0].day,
        ...(never ? { never: never } : null),
        ...(always ? { always: always } : null),
        ...(ranges && ranges.length > 0 ? { ranges: ranges } : null),
      };
    })
  );
};

export const getTimeSpanForNextDay = (
  input: ParsedTimeSpan
): ParsedTimeSpan => {
  if (input.dayOfWeek) {
    const currentDay = days.indexOf(input.dayOfWeek);
    const nextDay = days[(currentDay + 1) % days.length];
    return {
      ...input,
      dayOfWeek: nextDay,
    };
  } else {
    const currentDate = set(new Date(), {
      ...(input.day ? { date: input.day } : null),
      ...(input.month ? { month: input.month - 1 } : null),
      ...(input.year ? { year: input.year } : null),
    });
    const nextDay = addDays(new Date(currentDate.getTime()), 1);
    return {
      ...input,
      ...(input.day ? { day: getDate(nextDay) } : null),
      ...(input.month ? { month: getMonth(nextDay) + 1 } : null),
      ...(input.year ? { year: getYear(nextDay) } : null),
    };
  }
};

export const constructParsedTimeSpanDatePart = (
  timeSpanDatePart: string
): Partial<ParsedTimeSpan> => {
  const matchResult = match(
    /^(\d{1,2})\.(?:(?:(\d{1,2})?\.)?(\d{4})?)$/,
    timeSpanDatePart
  );
  if (matchResult.length === 0) {
    return {
      dayOfWeek: timeSpanDatePart,
    };
  } else {
    return {
      ...(matchResult[1] ? { day: parseInt(matchResult[1]) } : null),
      ...(matchResult[2] ? { month: parseInt(matchResult[2]) } : null),
      ...(matchResult[3] ? { year: parseInt(matchResult[3]) } : null),
    };
  }
};

export const sortParsedTimeSpans = (spans: ParsedTimeSpan[]) => {
  return [
    ...spans.filter((s) => s.year),
    ...spans.filter((s) => s.month && !s.year),
    ...spans.filter((s) => s.day && !s.month && !s.year),
    ...spans.filter((s) => s.dayOfWeek && !s.day && !s.month && !s.year),
    ...spans.filter((s) => !s.dayOfWeek && !s.day && !s.month && !s.year),
  ];
};

export const isParsedTimeSpanRelatedToDate = (
  span: ParsedTimeSpan,
  current: IsoDateTime,
  timezone: string
): boolean => {
  const currentDate = utcToZonedTime(current, timezone);

  if (
    (span.year != null && getYear(currentDate) !== span.year) ||
    (span.month != null && getMonth(currentDate) + 1 !== span.month) ||
    (span.day != null && getDate(currentDate) !== span.day) ||
    (span.dayOfWeek != null && format(currentDate, 'EEE') !== span.dayOfWeek)
  ) {
    return false;
  }

  return true;
};
