import { GetServerSidePropsContext } from 'next';
import { parseCookies, setCookie, destroyCookie } from 'nookies';
import { NODE_ENV } from './envs';

export const ACCESS_TOKEN_COOKIE_NAME = 'ktown-access-token';

export const COOKIE_MAX_AGE = 30 * 24 * 60 * 60; // ~ 30 days
export const COOKIE_OPTIONS = {
  maxAge: COOKIE_MAX_AGE,
  secure: NODE_ENV === 'production',
  path: '/',
};

export const getAccessTokenCookie = (context?: GetServerSidePropsContext) =>
  parseCookies(context)[ACCESS_TOKEN_COOKIE_NAME];

export const setAccessTokenCookie = (
  value: string,
  maxAge: number,
  context?: GetServerSidePropsContext
) =>
  setCookie(context, ACCESS_TOKEN_COOKIE_NAME, value, {
    ...COOKIE_OPTIONS,
    maxAge,
  });

export const destroyAccessTokenCookie = (context?: GetServerSidePropsContext) =>
  destroyCookie(context, ACCESS_TOKEN_COOKIE_NAME, COOKIE_OPTIONS);
