import React from 'react';
import Link, { LinkProps } from 'next/link';
import cn from 'classnames';

import styles from './NextLink.module.scss';

export interface NextLinkProps extends LinkProps {
  kind?: 'primary' | 'secondary';
  isUnderlined?: boolean;
  isOpacityHover?: boolean;
  className?: string;
}

const NextLink: React.FC<NextLinkProps> = ({
  children,
  href,
  kind = 'primary',
  isUnderlined = false,
  isOpacityHover = true,
  className,
  ...props
}) => (
  <Link {...props} href={href}>
    <a
      className={cn(
        styles.base,
        styles[kind],
        {
          underline: isUnderlined,
          'hover:opacity-hover': isOpacityHover,
        },
        className
      )}
      data-testid="next-link"
    >
      {children}
    </a>
  </Link>
);

export default NextLink;
