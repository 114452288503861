export type IconType =
  | 'basket'
  | 'basket-active'
  | 'basket-check'
  | 'basket-dot'
  | 'basket-red'
  | 'basket-white'
  | 'bell'
  | 'burger'
  | 'calendar'
  | 'card'
  | 'chevron-down'
  | 'chevron-down-active'
  | 'chevron-left'
  | 'chevron-left-circle'
  | 'chevron-left-double'
  | 'chevron-right'
  | 'chevron-right-circle'
  | 'chevron-right-double'
  | 'chip'
  | 'controls'
  | 'controls-active'
  | 'cross'
  | 'cross-active'
  | 'cross-white'
  | 'distance'
  | 'dots'
  | 'dots-active'
  | 'email'
  | 'facebook'
  | 'fork-knife'
  | 'fork-knife-active'
  | 'gift'
  | 'link'
  | 'linkedin'
  | 'mail-white'
  | 'map-pin'
  | 'map-pin-active'
  | 'mastercard'
  | 'phone'
  | 'phone-white'
  | 'question'
  | 'quotation'
  | 'restaurant'
  | 'schedule'
  | 'schedule-thin'
  | 'scroll-up'
  | 'search'
  | 'search-active'
  | 'search-mobile'
  | 'search-mobile-active'
  | 'search-white'
  | 'share'
  | 'star-empty'
  | 'star-full'
  | 'star-part'
  | 'thumbs-down'
  | 'thumbs-up'
  | 'time'
  | 'tools'
  | 'tools-active'
  | 'twitter'
  | 'view-cards'
  | 'view-cards-active'
  | 'view-list'
  | 'view-list-active'
  | 'visa-color'
  | 'wallet';

import React from 'react';

export type IconProps = {
  icon: IconType;
  alt: string;
  className?: string;
};

const Icon: React.FC<IconProps> = ({ icon, alt, className }) => (
  <img
    src={`/icons/${icon}.svg`}
    alt={alt}
    className={className}
    aria-hidden="true"
    data-testid="icon-main"
  />
);

export default Icon;
