import { TransitionClasses } from '@headlessui/react';

export const TRANSITION_PROPS_FADE_OPACITY: TransitionClasses = {
  enter: 'transition-opacity ease-linear duration-100',
  enterFrom: 'opacity-0',
  enterTo: 'opacity-100',
  leave: 'transition-opacity ease-linear duration-100',
  leaveFrom: 'opacity-100',
  leaveTo: 'opacity-0',
};
