import React, { forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

import TextField, { TextFieldProps } from './TextField';

interface TextFieldFormProps extends TextFieldProps {
  name: string;
  error?: FieldError;
}

const TextFieldForm = forwardRef<HTMLInputElement, TextFieldFormProps>(
  ({ name, error, className, ...props }, ref) => {
    const errorId = name + '-error-message';
    const errorMessage = error?.message;

    return (
      <div className={className}>
        <TextField
          {...props}
          ref={ref}
          name={name}
          className="w-full"
          aria-invalid={!!errorMessage}
          aria-errormessage={errorId}
          hasError={!!errorMessage}
        />

        {!!errorMessage && (
          <div
            id={errorId}
            className="mt-1 text-red text-sm"
            data-testid="TextFieldForm-error-message"
          >
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

export default TextFieldForm;
