import React, { forwardRef, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import AsyncReactSelect from 'react-select/async';

import { SelectField, SelectFieldOption, SelectFieldProps } from '../index';
import { getPlacesAutosuggestions } from '../../../api/methods/services';
import { ERROR_MESSAGE_GENERIC } from '../../../constants/common';

type AddressSelectFieldProps = {
  className?: string;
} & Pick<
  SelectFieldProps,
  'defaultValue' | 'onChange' | 'onBlur' | 'value' | 'name'
>;

const AddressSelectField = forwardRef<
  AsyncReactSelect<SelectFieldOption>,
  AddressSelectFieldProps
>(({ className, onChange, ...props }, ref) => {
  const [timeoutHandle, setTimeoutHandle] = useState<ReturnType<
    typeof setTimeout
  > | null>(null);

  const loadPlacesOptions = useCallback(
    (inputValue, callback) => {
      if (timeoutHandle) {
        clearTimeout(timeoutHandle);
      }

      setTimeoutHandle(
        setTimeout(async () => {
          const { data } = await getPlacesAutosuggestions(inputValue);

          if (data?.status !== 'OK' && data?.status !== 'ZERO_RESULTS') {
            toast.error(ERROR_MESSAGE_GENERIC);
            return [];
          }

          const options = data?.predictions?.map<SelectFieldOption<string>>(
            (prediction) => ({
              label: prediction.description,
              value: prediction.place_id,
            })
          );

          callback(options);
        }, 400)
      );
    },
    [timeoutHandle, setTimeoutHandle]
  );

  return (
    <SelectField
      {...props}
      async
      className={className}
      getOptionLabel={({ label }) => (!label ? 'test' : label)}
      hideDropdownIndicator
      kind="dark"
      loadOptions={loadPlacesOptions}
      noOptionsMessage={() => 'Start typing to get address predictions...'}
      onChange={onChange}
      placeholder="Select delivery address"
      ref={ref}
      size="big"
    />
  );
});

export default AddressSelectField;
