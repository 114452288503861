import axios from 'axios';
import {
  GooglePlacesAutocompleteResponse,
  GooglePlacesDetailsResponse,
} from '../../types/services';

/**
 * Gets autosuggestions from Google Places API for a specific input string.
 * @param input Input string for which to get autosuggestion results
 */
export const getPlacesAutosuggestions = (input: string) =>
  axios.get<GooglePlacesAutocompleteResponse>('/api/gplaces-autosuggestions', {
    params: {
      input,
    },
  });

/**
 * Gets details of a specific Place from Google Places API.
 * @param placeId Place ID for which to get details of
 */
export const getPlacesDetail = (placeId: string) =>
  axios.get<GooglePlacesDetailsResponse>('/api/gplaces-details', {
    params: {
      place_id: placeId,
    },
  });
