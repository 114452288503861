module.exports = {
  mode: 'jit',
  purge: [
    './pages/**/*.js',
    './pages/**/*.ts',
    './pages/**/*.jsx',
    './pages/**/*.tsx',
    './components/**/*.js',
    './components/**/*.ts',
    './components/**/*.jsx',
    './components/**/*.tsx',
  ],
  darkMode: false,
  theme: {
    extend: {
      fontSize: {
        xxs: '0.6875rem',
        '2lg': '1.1875rem',
        '8.5xl': '6.25rem',
      },
      height: {
        13: '3.25rem',
        30: '7.5rem',
        inherit: 'inherit',
      },
      opacity: {
        hover: '0.65',
        disabled: '0.35',
        55: '0.55',
      },
      spacing: {
        4.5: '1.125rem',
        7.5: '1.875rem',
        9: '2.25rem',
        15: '3.75rem',
        22: '5.5rem',
        26: '6.5rem',
        27: '6.75rem',
        30: '7.5rem',
        30.5: '7.625rem',
        33: '8.25rem',
        34: '8.5rem',
        37: '9.25rem',
        38: '9.5rem',
        41: '10.25rem',
        45: '10.25rem',
        50: '12.5rem',
        54: '13.5rem',
        82: '20.5rem',
        85: '21.25rem',
        90: '22.5rem',
        100: '25rem',
        104: '26rem',
        112: '28rem',
      },
      transitionProperty: {
        spacing: 'margin, padding',
      },
      lineHeight: {
        smaller: '1.4',
      },
      width: {
        26: '6.5rem',
      },
      minWidth: {
        3.5: '0.875rem',
      },
      borderWidth: {
        5: '5px',
      },
    },
    colors: {
      red: {
        DEFAULT: '#FF3E1C',
        light: '#FBE5E1',
        dark: '#D0112B',
      },
      brown: {
        DEFAULT: '#432F2A',
        light: '#A19693',
      },
      gray: {
        DEFAULT: '#EDEAE8',
        light: '#F6F4F4',
        lighter: '#FAF8F7',
        dark: '#E5E2E1',
        darker: '#D6D3D2',
      },
      green: {
        DEFAULT: '#40B916',
        light: '#F1FFEC',
      },
      blue: {
        DEFAULT: '#EBEEF6',
        light: '#F1F3F8',
        lighter: '#E0E6ED',
        dark: '#8492A5',
      },
      info: {
        DEFAULT: '#005FB8',
        light: '#BADCFC',
      },
      warning: {
        DEFAULT: '#AF7D00',
        light: '#FCE9BA',
      },
      success: {
        DEFAULT: '#19B200',
        light: '#C3FCBA',
      },
      error: {
        DEFAULT: '#FF3E1C',
        light: '#FFDAD3',
      },
      white: '#FFFFFF',
      overlay: 'rgba(60, 52, 50, 0.5)',
      transparent: 'transparent',
    },
    boxShadow: {
      DEFAULT: '0px 4px 24px rgba(78, 47, 39, 0.11)',
      xs: '0px -1px 9px rgba(0, 0, 0, 0.07)',
      'top-sm': '0px -5px 5px rgba(0, 0, 0, 0.08)',
      sm: '0px 2px 13px rgba(67, 47, 42, 0.04)',
      md: '0px 2px 13px rgba(67, 47, 42, 0.1)',
      lg: '0px 4px 30px rgba(78, 47, 39, 0.16)',
      'bottom-lg': '0px 12px 12px rgba(78, 47, 39, 0.11)',
      none: 'none',
    },
    container: {
      center: true,
      screens: {
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
        '2xl': '1536px',
      },
      padding: {
        DEFAULT: '1rem',
        md: '1.5rem',
      },
    },
  },
};
