import React, { createContext, useCallback, useContext, useState } from 'react';
import immerProduce from 'immer';
import { equals } from 'ramda';

import {
  DeliveryMethod,
  LocalOrderState,
  LocalOrderStateItem,
  OrderContextInterface,
  OrderStateError,
  OrderTotals,
} from '../../../types/orders';
import { APIAddress } from '../../../types/common';
import { calculateOrderTotals } from '../../../helpers/orders';
import { StoreMenu } from '../../../types/stores';

export const ORDER_CONTEXT_DEFAULTS: OrderContextInterface = {
  state: null,
  clearState: () => undefined,
  addItem: () => ({
    error: true,
    type: 'not_defined',
  }),
  removeItem: () => undefined,
  setDeliveryAddress: () => undefined,
  setDeliveryType: () => undefined,
  setGoGreen: () => undefined,
  setItemQuantity: () => undefined,
  setScheduledTime: () => undefined,
  getTotals: () => null,
  getItemsCount: () => null,
};

export const OrderContext = createContext<OrderContextInterface>(
  ORDER_CONTEXT_DEFAULTS
);

export const useOrderContext = () => useContext(OrderContext);

const LOCAL_ORDER_STATE_KEY = 'orderState';

const OrderContextProvider: React.FC = ({ children }) => {
  const [stateStorage, setStateStorage] = useState<LocalOrderState | null>(
    () => {
      if (typeof window === 'undefined') {
        return null;
      }

      const state = window.localStorage.getItem(LOCAL_ORDER_STATE_KEY);

      if (typeof state !== 'string') {
        return null;
      }

      return JSON.parse(state) as LocalOrderState;
    }
  );

  const clearState = useCallback((): void => {
    if (typeof window === 'undefined') {
      return;
    }

    setStateStorage(null);
    window.localStorage.removeItem(LOCAL_ORDER_STATE_KEY);
  }, [setStateStorage]);

  const addItem = useCallback(
    (
      storeId: LocalOrderState['storeId'],
      item: LocalOrderStateItem
    ): OrderStateError => {
      if (!stateStorage || stateStorage.items.length == 0) {
        const newState: LocalOrderState = {
          storeId,
          goGreen: false,
          scheduledFor: null,
          items: [item],
          deliveryMethod: null,
          note: null,
        };

        setStateStorage(newState);
        window.localStorage.setItem(
          LOCAL_ORDER_STATE_KEY,
          JSON.stringify(newState)
        );

        return {
          error: false,
        };
      } else {
        if (stateStorage.storeId !== storeId) {
          return {
            error: true,
            type: 'multiple_stores',
          };
        }

        let updatedState: LocalOrderState;
        const itemsWithSameProductId = stateStorage.items.filter(
          ({ productId }) => productId === item.productId
        );

        // Check if there already is the same product with the same modifiers
        if (itemsWithSameProductId.length > 0) {
          const duplicateItem = itemsWithSameProductId.find((similarItem) =>
            equals(item.modifierGroups, similarItem.modifierGroups)
          );

          if (!duplicateItem) {
            // There is no duplicate item, simply add a new one
            updatedState = immerProduce(stateStorage, (draft) => {
              draft.items.push(item);
            });
          } else {
            // There is a duplicate item, update it's quantity, but do not add a new item
            const duplicateItemIndex = stateStorage.items.findIndex(
              ({ itemId }) => itemId === duplicateItem.itemId
            );

            updatedState = immerProduce(stateStorage, (draft) => {
              draft.items[duplicateItemIndex].quantity += item.quantity ?? 1;
            });
          }
        } else {
          updatedState = immerProduce(stateStorage, (draft) => {
            draft.items.push(item);
          });
        }

        setStateStorage(updatedState);
        window.localStorage.setItem(
          LOCAL_ORDER_STATE_KEY,
          JSON.stringify(updatedState)
        );

        return {
          error: false,
        };
      }
    },
    [stateStorage, setStateStorage]
  );

  const removeItem = useCallback(
    (itemId: LocalOrderStateItem['itemId']): void => {
      if (!stateStorage) {
        return;
      }

      const updatedState = immerProduce(stateStorage, (draft) => {
        draft.items = draft.items.filter((item) => item.itemId !== itemId);
      });

      if (updatedState.items.length === 0) {
        clearState();
        return;
      }

      setStateStorage(updatedState);
      window.localStorage.setItem(
        LOCAL_ORDER_STATE_KEY,
        JSON.stringify(updatedState)
      );
    },
    [stateStorage, setStateStorage, clearState]
  );

  const setItemQuantity = useCallback(
    (
      itemId: LocalOrderStateItem['itemId'],
      quantity: LocalOrderStateItem['quantity']
    ): void => {
      if (!stateStorage) {
        return;
      }

      // Clear the entire state if there's only one item and it's new quantity is 0
      if (stateStorage.items.length === 1 && quantity === 0) {
        if (stateStorage.items.find((item) => item.itemId === itemId)) {
          clearState();
          return;
        }
      }

      const updatedState = immerProduce(stateStorage, (draft) => {
        draft.items = stateStorage.items.map((item) => {
          if (item.itemId !== itemId) {
            return item;
          }

          return {
            ...item,
            quantity,
          };
        });
      });

      setStateStorage(updatedState);
      window.localStorage.setItem(
        LOCAL_ORDER_STATE_KEY,
        JSON.stringify(updatedState)
      );
    },
    [stateStorage, setStateStorage, clearState]
  );

  const setDeliveryAddress = useCallback(
    (address: APIAddress): void => {
      if (!stateStorage) {
        return;
      }

      const updatedState = immerProduce(stateStorage, (draft) => {
        draft.deliveryMethod = {
          type: 'delivery',
          address: address,
          note: null,
        };
      });

      setStateStorage(updatedState);
      window.localStorage.setItem(
        LOCAL_ORDER_STATE_KEY,
        JSON.stringify(updatedState)
      );
    },
    [stateStorage, setStateStorage]
  );

  const setDeliveryType = useCallback(
    (deliveryType: DeliveryMethod['type']): void => {
      if (!stateStorage) {
        return;
      }

      const updatedState = immerProduce(stateStorage, (draft) => {
        draft.deliveryMethod = {
          type: deliveryType,
          address: null,
          note: null,
        };
      });

      setStateStorage(updatedState);
      window.localStorage.setItem(
        LOCAL_ORDER_STATE_KEY,
        JSON.stringify(updatedState)
      );
    },
    [stateStorage, setStateStorage]
  );

  const setGoGreen = useCallback(
    (goGreen: LocalOrderState['goGreen']): void => {
      if (!stateStorage) {
        return;
      }

      const updatedState = immerProduce(stateStorage, (draft) => {
        draft.goGreen = goGreen;
      });

      setStateStorage(updatedState);
      window.localStorage.setItem(
        LOCAL_ORDER_STATE_KEY,
        JSON.stringify(updatedState)
      );
    },
    [stateStorage, setStateStorage]
  );

  const setScheduledTime = useCallback(
    (time: LocalOrderState['scheduledFor']) => {
      if (!stateStorage) {
        return;
      }

      const updatedState = immerProduce(stateStorage, (draft) => {
        draft.scheduledFor = time;
      });

      setStateStorage(updatedState);
      window.localStorage.setItem(
        LOCAL_ORDER_STATE_KEY,
        JSON.stringify(updatedState)
      );
    },
    [stateStorage, setStateStorage]
  );

  const getTotals = useCallback(
    (storeMenu: StoreMenu): OrderTotals | null => {
      if (!stateStorage) {
        return null;
      }

      return calculateOrderTotals(stateStorage, storeMenu);
    },
    [stateStorage]
  );

  const getItemsCount = useCallback(() => {
    if (!stateStorage) {
      return null;
    }

    return stateStorage.items.reduce<number>(
      (acc, curr) => acc + curr.quantity,
      0
    );
  }, [stateStorage]);

  return (
    <OrderContext.Provider
      value={{
        state: stateStorage,
        clearState,
        addItem,
        removeItem,
        setDeliveryAddress,
        setDeliveryType,
        setGoGreen,
        setItemQuantity,
        setScheduledTime,
        getTotals,
        getItemsCount,
      }}
    >
      {children}
    </OrderContext.Provider>
  );
};

export default OrderContextProvider;
