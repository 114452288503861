import { CARDCONNECT_URL } from '../helpers/envs';

export const ERROR_GOOGLE_PLACES_DETAIL =
  'Unable to resolve address, please try again later.';

export const CARDCONNECT_CSS = `
    html {
      font-family: 'Work Sans', sans-serif;
    }

    body {
      margin: 0;
    }

    label {
      display: none;
    }
    
    br {
      display: none;
    }

    input, ::before, ::after {
      box-sizing: border-box;
      border-width: 0;
      border-style: solid;
      border-color: currentColor;
    }

    input {
      background-color: rgb(246 244 244);
      padding-left: 1rem;
      padding-right: 1rem;
      height: 3.25rem;
      border-radius: 0.375rem;
      border: none;
      color: rgb(67, 47, 42);
      font-size: 1rem;
    }
    
    input:focus{
      box-shadow: 0 0 0 2px rgb(214, 211, 210) inset;
      outline: none;
    }
    
    input::placeholder {
      color: rgb(161 150 147);
    }

    input {
      width: 100%;
      margin: 0;
      margin-bottom: 1rem;
      border-width: 1px;
    }

    .error {
      border: 1px solid red;
    }
  `;

/*
 * More info you can find in the docs https://developer.cardconnect.com/hosted-iframe-tokenizer#iFrame-styling
 */
export const CARDCONNECT_QUERY_PARAMS: Record<string, string> = {
  formatinput: 'true',
  invalidcreditcardevent: 'true',
  sendcssloadedevent: 'true',
  tokenizewheninactive: 'true',
};

export const CARDCONNECT_SRC = encodeURI(
  `${CARDCONNECT_URL}/itoke/ajax-tokenizer.html?` +
    `placeholder=XXXX XXXX XXXX XXXX&css="${CARDCONNECT_CSS}"&` +
    new URLSearchParams(CARDCONNECT_QUERY_PARAMS).toString()
);
