import React, { forwardRef, InputHTMLAttributes } from 'react';
import cn from 'classnames';

import styles from './TextField.module.scss';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder: string;
  kind?: 'dark' | 'light';
  hasError?: boolean;
}

const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ kind = 'light', type = 'text', hasError, className, ...props }, ref) => (
    <input
      {...props}
      type={type}
      ref={ref}
      className={cn(
        styles.base,
        styles[kind],
        {
          [styles.error]: hasError,
        },
        className
      )}
    />
  )
);

export default TextField;
