import React, { ButtonHTMLAttributes } from 'react';
import cn from 'classnames';

import { Icon, IconProps } from '../';

import styles from './IconButton.module.scss';

export type IconButtonProps = {
  a11yLabel: string;
  icon: IconProps['icon'];
  iconAlt: IconProps['alt'];
  iconClassName?: IconProps['className'];
  className?: string;
  children?: never;
  dataTestId?: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const IconButton: React.FC<IconButtonProps> = ({
  a11yLabel,
  icon,
  iconAlt,
  iconClassName,
  type = 'button',
  className,
  dataTestId,
  ...props
}) => (
  <button
    {...props}
    type={type}
    className={cn(styles.base, className)}
    data-testid={dataTestId ?? 'icon-button-main'}
  >
    <Icon icon={icon} alt={iconAlt} className={iconClassName} />

    <span className="sr-only" data-testid="icon-button-a11y-label">
      {a11yLabel}
    </span>
  </button>
);

export default IconButton;
