import React, { InputHTMLAttributes, useCallback, useState } from 'react';
import { useUID } from 'react-uid';
import cn from 'classnames';

import styles from './Counter.module.scss';

type CounterLabelVariants =
  | {
      label: string;
      a11yLabel?: never;
    }
  | {
      a11yLabel: string;
      label?: never;
    };

type CounterProps = {
  onChange: (value: number) => void;
  value?: number;
  min?: number;
  max?: number;
  size?: 'xs' | 'lg';
  defaultValue?: number;
} & CounterLabelVariants &
  Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'type' | 'name' | 'min' | 'max' | 'size'
  >;

const Counter = ({
  label,
  a11yLabel,
  onChange,
  min = 1,
  max = 99,
  size = 'xs',
  value,
  defaultValue,
  className,
  ...props
}: CounterProps) => {
  const [internalValue, setInternalValue] = useState(
    defaultValue ?? value ?? min
  );

  const uid = useUID();

  const handleChange = useCallback(
    (newValue: number) => {
      const clampedValue = Math.min(Math.max(newValue, min), max);

      onChange(clampedValue);
      setInternalValue(clampedValue);
    },
    [min, max, setInternalValue, onChange]
  );

  return (
    <div className={cn('flex items-center', className)}>
      <div className={cn(styles.wrapper, styles[size])}>
        <button
          type="button"
          data-testid="counter-button-decrement"
          onClick={() => handleChange(internalValue - 1)}
          className={styles.button}
        >
          <span className="sr-only">Decrement value</span>
          <span>-</span>
        </button>

        {!!a11yLabel && (
          <label htmlFor={`counter-${uid}-input`} className="sr-only">
            {a11yLabel}
          </label>
        )}

        <input
          {...props}
          className={styles.input}
          data-testid="counter-input"
          id={`counter-${uid}-input`}
          max={max}
          min={min}
          type="number"
          value={internalValue}
          disabled
        />

        <button
          type="button"
          data-testid="counter-button-increment"
          onClick={() => handleChange(internalValue + 1)}
          className={styles.button}
        >
          <span className="sr-only">Increment value</span>
          <span>+</span>
        </button>
      </div>

      {!!label && (
        <label htmlFor={`counter-${uid}-input`} className="ml-4">
          {label}
        </label>
      )}
    </div>
  );
};

export default Counter;
