import React, { forwardRef, InputHTMLAttributes, ReactNode } from 'react';
import { useUID } from 'react-uid';
import cn from 'classnames';

import styles from './CheckboxField.module.scss';

type CheckboxFieldProps = {
  name: string;
  label: string | ReactNode;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'name' | 'id'>;

const CheckboxField = forwardRef<HTMLInputElement, CheckboxFieldProps>(
  ({ label, className, ...props }, ref) => {
    const uid = useUID();

    return (
      <label
        htmlFor={`checkboxField-${uid}-input`}
        className={cn(styles.wrapper, className)}
      >
        <input
          {...props}
          ref={ref}
          id={`checkboxField-${uid}-input`}
          className="sr-only"
          type="checkbox"
        />

        <span className={styles.checkmark} />

        <span className="text-base select-none">{label}</span>
      </label>
    );
  }
);

export default CheckboxField;
