import React, { memo } from 'react';
import cn from 'classnames';

import styles from './Separator.module.scss';

type SeparatorProps = {
  className?: string;
} & (
  | {
      type?: 'horizontal';
      content?: string;
    }
  | {
      type?: 'vertical';
      content?: never;
    }
);

const Separator: React.FC<SeparatorProps> = memo(
  ({ type = 'horizontal', content, className }) => {
    if (type === 'vertical') {
      return (
        <div
          className={cn(styles.wrapperVertical, className)}
          aria-hidden="true"
        >
          <div className="h-full border-l border-gray-dark" />
        </div>
      );
    }

    return (
      <div className={cn('flex flex-row items-center my-4', className)}>
        <div className="flex-1 border-t border-gray-dark" aria-hidden="true" />

        {content !== undefined && (
          <>
            <div className="relative flex justify-center">
              <span
                data-testid="separator-content"
                className="px-5 text-sm text-brown"
              >
                {content}
              </span>
            </div>

            <div
              className="flex-1 border-t border-gray-dark"
              aria-hidden="true"
            />
          </>
        )}
      </div>
    );
  }
);

export default Separator;
