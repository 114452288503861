import { isPointInPolygon } from 'geolib';
import immerProduce from 'immer';
import {
  addDays,
  addMinutes,
  parseISO,
  startOfToday,
  startOfHour,
  endOfDay,
  isBefore,
} from 'date-fns';

import {
  Store,
  StoreMenu,
  StoreMenuModifier,
  StoreMenuModifierGroup,
  StoreMenuProduct,
} from '../types/stores';
import {
  CreateOrderRequestItemModifier,
  CreateOrderRequestPayload,
  LocalOrderState,
  LocalOrderStateItem,
  OrderTotals,
} from '../types/orders';
import { DEFAULT_TIMEZONE } from '../constants/common';
import { UserProfile } from '../types/auth';
import { APIAddress } from '../types/common';
import { isDateTimeWithinTimeSpans } from './figure/datetime';
import { computeOrderItemTotals } from './figure/price';

const AVAILABLE_DELIVERY_DAYS_AHEAD = 8;

export const composeAvailableDeliveryDates = (): Date[] => {
  if (typeof window === 'undefined') {
    return [];
  }

  try {
    const dates = [startOfToday()];

    for (let i = 1; i < AVAILABLE_DELIVERY_DAYS_AHEAD; ++i) {
      dates.push(addDays(dates[i - 1], 1));
    }

    return dates;
  } catch (e) {
    // TODO: Report this error to some service
  }

  return [];
};

export const composeAvailableDeliveryTimes = (
  timezone = DEFAULT_TIMEZONE,
  hoursOfOperation: Store['hoursOfOperation'],
  date: string
): Date[] => {
  if (typeof window === 'undefined' || !location) {
    return [];
  }

  try {
    const result = [startOfHour(parseISO(date))];
    const end = endOfDay(result[0]);

    while (isBefore(result[result.length - 1], end)) {
      result.push(addMinutes(result[result.length - 1], 15));
    }

    result.splice(result.length - 1, 1);

    return result.filter((date) =>
      isDateTimeWithinTimeSpans(
        hoursOfOperation.spans,
        date.toISOString(),
        timezone
      )
    );
  } catch (e) {
    // TODO: Report this error to some service
  }

  return [];
};

export const formatPrice = (
  price: StoreMenuProduct['price'] | undefined,
  quantity = 1
) => {
  if (!price && price !== 0) {
    return null;
  }

  if (typeof price === 'number') {
    return `\$${((price * quantity) / 100).toFixed(2)}`;
  }

  if (typeof price === 'object' && price.length !== 0) {
    return `\$${((price[0] * quantity) / 100).toFixed(2)} - \$${(
      (price[1] * quantity) /
      100
    ).toFixed(2)}`;
  }
};

/**
 * Checks whether given coordinates are in a delivery area of the given restaurant
 *
 * @param lat Latitude coordinate of the point in question
 * @param lng Longitude coordinate of the point in question
 * @param store Store with a delivery area
 */
export const areCoordinatesInDeliveryArea = (
  lat: number,
  lng: number,
  store: Store
): boolean => {
  if (!lat || !lng || !store) {
    return false;
  }

  if (store.delivery === undefined) {
    return true;
  }

  if (store.delivery.area !== undefined) {
    if (!store.delivery.area.zones?.length) {
      return false;
    }

    const results = store.delivery.area.zones.map<boolean>((zone) => {
      const polygon = zone.map(([latitude, longitude]) => ({
        latitude,
        longitude,
      }));

      return isPointInPolygon({ latitude: lat, longitude: lng }, polygon);
    });

    return results.some((res) => res);
  }

  return false;
};

/**
 * Merges remote (API) and local (localStorage) remoteProduct objects
 *
 * @param remoteProduct Remote product (product from API)
 * @param localProduct Local product (product form localStorage)
 */
export const mergeLocalAndRemoteProducts = (
  remoteProduct: StoreMenuProduct,
  localProduct: LocalOrderStateItem
): StoreMenuProduct | null => {
  if (!remoteProduct || !localProduct) {
    return null;
  }

  try {
    return immerProduce(remoteProduct, (draft) => {
      draft.quantity = localProduct.quantity;

      const modifierGroups: StoreMenuModifierGroup[] = [];

      draft.modifierGroups.forEach(({ id: modifierGroupId }) => {
        const modifierGroup = remoteProduct.modifierGroups.find(
          ({ id }) => id === modifierGroupId
        );

        if (!modifierGroup) {
          return;
        }

        const localModifierGroup = localProduct.modifierGroups.find(
          ({ id }) => id === modifierGroupId
        );

        if (!localModifierGroup) {
          return null;
        }

        const modifiers: StoreMenuModifier[] = [];

        localModifierGroup.modifiers.forEach(({ id: modifierId, quantity }) => {
          const modifierData = modifierGroup.modifiers.find(
            ({ id }) => id === modifierId
          );

          if (!modifierData) {
            return;
          }

          modifiers.push({
            ...modifierData,
            quantity,
          });
        });

        modifierGroups.push({
          ...modifierGroup,
          modifiers,
        });
      });

      draft.modifierGroups = modifierGroups;
    });
  } catch (e) {
    // TODO: Report this error to some service
    return null;
  }
};

/**
 * Calculates total order prices based on store menu and local product quantities
 *
 * @param localState Local order state
 * @param storeMenu Store menu
 */
export const calculateOrderTotals = (
  localState: LocalOrderState,
  storeMenu: StoreMenu
): OrderTotals | null => {
  if (!localState || !storeMenu) {
    return null;
  }

  const initialValues: OrderTotals = {
    quantity: 0,
    price: 0,
    basePrice: 0,
    subtotal: 0,
    pricingsTotal: 0,
    taxesTotal: 0,
    total: 0,
  };

  try {
    return localState.items.reduce<OrderTotals>(
      (acc, { itemId, productId, categoryId, modifierGroups, quantity }) => {
        const category = storeMenu.categories?.find(
          ({ id }) => id === categoryId
        );

        const product = category?.products?.find(({ id }) => id === productId);

        if (!product) {
          return acc;
        }

        const productWithQuantities = mergeLocalAndRemoteProducts(product, {
          itemId,
          productId,
          categoryId,
          modifierGroups,
          quantity,
        });

        if (!productWithQuantities) {
          return acc;
        }

        const itemTotals = computeOrderItemTotals(productWithQuantities);
        itemTotals.quantity = itemTotals.quantity ?? 1;

        return {
          quantity: (acc.quantity ?? 1) + itemTotals.quantity,
          price: acc.price + itemTotals.price * itemTotals.quantity,
          basePrice: acc.basePrice + itemTotals.basePrice * itemTotals.quantity,
          subtotal: acc.subtotal + itemTotals.subtotal * itemTotals.quantity,
          pricingsTotal:
            acc.pricingsTotal + itemTotals.pricingsTotal * itemTotals.quantity,
          taxesTotal:
            acc.taxesTotal + itemTotals.taxesTotal * itemTotals.quantity,
          total: acc.total + itemTotals.total * itemTotals.quantity,
        };
      },
      initialValues
    );
  } catch (e) {
    // TODO: Report this error to some service
    return null;
  }
};

/**
 * Transforms local order state data to format required by API in the create order request
 *
 * @param localOrderState Local order state data to be transformed
 */
export const getCreateOrderRequestPayload = (
  localOrderState: LocalOrderState
): Pick<
  CreateOrderRequestPayload,
  'storeId' | 'scheduledFor' | 'items'
> | null => {
  if (!localOrderState?.items?.length || !localOrderState?.deliveryMethod) {
    return null;
  }

  return {
    storeId: localOrderState.storeId,
    scheduledFor: localOrderState.scheduledFor,
    items: localOrderState.items.map((item) => {
      const modifiers: CreateOrderRequestItemModifier[] = [];

      item.modifierGroups.forEach((modifiersGroup) => {
        modifiersGroup.modifiers.forEach((modifier) => {
          modifiers.push({
            groupId: modifiersGroup.id,
            modifierId: modifier.id,
            quantity: modifier.quantity,
          });
        });
      });

      return {
        productId: item.productId,
        quantity: item.quantity,
        modifiers,
      };
    }),
  };
};

/**
 * Extracts address with given Google Place ID either from local order state or user profile.
 * If address with given Google Place ID isn't found in neither of these two objects, null is returned.
 *
 * @param googlePlaceId ID of a Google Place for which to find the address
 * @param orderState Local order state object
 * @param userProfile User profile object
 */
export const extractAddressByGooglePlaceId = (
  googlePlaceId: string,
  orderState: LocalOrderState,
  userProfile?: UserProfile
) => {
  let selectedAddress: APIAddress | undefined;

  const orderStateAddress = orderState?.deliveryMethod?.address;

  if (orderStateAddress?.googlePlaceId === googlePlaceId) {
    selectedAddress = orderStateAddress;
  } else {
    selectedAddress = userProfile?.deliveriesInfo?.find(
      ({ address }) => address.googlePlaceId === googlePlaceId
    )?.address;
  }

  return selectedAddress ?? null;
};
