import React, { forwardRef } from 'react';
import ReactSelect, {
  components,
  ControlProps,
  OptionTypeBase,
  Props,
} from 'react-select';
import AsyncReactSelect, { AsyncProps } from 'react-select/async';
import { Transition } from '@headlessui/react';
import { useUID } from 'react-uid';
import cn from 'classnames';

import Icon, { IconType } from '../../common/icon/Icon';
import { TRANSITION_PROPS_FADE_OPACITY } from '../../../constants/transitions';

import styles from './SelectField.module.scss';

export interface SelectFieldOption<T = string | number> extends OptionTypeBase {
  label: string;
  value: T;
}

type CommonSelectProps = {
  className?: string;
  defaultValue?: SelectFieldOption;
  hasError?: boolean;
  hideChevron?: boolean;
  hideDropdownIndicator?: boolean;
  kind?: 'default' | 'dark' | 'transparent';
  label?: string;
  labelClassname?: string;
  labelPosition?: 'external' | 'external-inline' | 'internal' | 'hidden';
  optionIcon?: IconType;
  optionIconActive?: string;
  optionIconAlt?: string;
  optionIconClassName?: string;
  placeholder?: string;
  size?: 'small' | 'big';
} & Pick<
  Props<SelectFieldOption>,
  | 'getOptionLabel'
  | 'inputValue'
  | 'isDisabled'
  | 'name'
  | 'noOptionsMessage'
  | 'onBlur'
  | 'onChange'
  | 'onInputChange'
  | 'tabSelectsValue'
  | 'value'
>;

type AsyncSelectProps = {
  async?: true;
} & Pick<
  AsyncProps<SelectFieldOption>,
  'cacheOptions' | 'defaultOptions' | 'loadOptions'
>;

type DefaultSelectProps = {
  async?: false;
} & Pick<Props<SelectFieldOption>, 'options'>;

export type SelectFieldProps = CommonSelectProps &
  (DefaultSelectProps | AsyncSelectProps);

const CustomControl = (props: ControlProps<SelectFieldOption, false>) => {
  const { isFocused, selectProps } = props;

  return (
    <components.Control
      {...props}
      className={cn(styles.control, {
        [styles.focused]: isFocused,
        [styles.error]: selectProps.hasError,
      })}
    >
      {props.selectProps.labelType === 'internal' && (
        <p className="font-semibold text-brown text-sm pl-2">
          {props.selectProps.label}
        </p>
      )}
      {props.children}
    </components.Control>
  );
};

const CustomValueContainer = (props) => (
  <components.ValueContainer {...props} className={styles.valueContainer}>
    {props.children}
  </components.ValueContainer>
);

const CustomPlaceholder = (props) => (
  <components.Placeholder {...props}>
    <Transition show={!props.isFocused} {...TRANSITION_PROPS_FADE_OPACITY}>
      {props.children}
    </Transition>
  </components.Placeholder>
);

const CustomDropdownIndicator = (props) =>
  !props.selectProps.hideChevron ? (
    <components.DropdownIndicator {...props}>
      <Icon
        icon="chevron-down"
        alt="Chevron down icon"
        className={
          props.selectProps.menuIsOpen ? 'transform rotate-180' : undefined
        }
      />
    </components.DropdownIndicator>
  ) : null;

const CustomOption = (props) => {
  const {
    children,
    className,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
  } = props;

  return (
    <div
      ref={innerRef}
      className={cn(
        {
          [styles.option]: true,
          [styles.focused]: isFocused,
          [styles.selected]: isSelected,
        },
        className
      )}
      {...innerProps}
    >
      {props.selectProps.optionIcon && (
        <Icon
          icon={
            props.isSelected
              ? props.selectProps.optionIconActive
              : props.selectProps.optionIcon
          }
          alt={props.selectProps.optionIconAlt}
          className={props.selectProps.optionIconClassName}
        />
      )}

      {children}
    </div>
  );
};

const SelectField = forwardRef<any, SelectFieldProps>(
  (
    {
      async,
      className,
      hasError = false,
      hideChevron = false,
      hideDropdownIndicator = false,
      kind = 'default',
      label,
      labelPosition = 'external',
      optionIcon,
      size = 'small',
      ...props
    },
    ref
  ) => {
    const uid = useUID();

    const commonProps = {
      'aria-label': label,
      hasError: hasError,
      hideChevron: hideChevron,
      instanceId: uid,
      label: label,
      labelType: labelPosition,
      optionIcon: optionIcon,
      components: {
        Control: CustomControl,
        Option: CustomOption,
        DropdownIndicator: hideDropdownIndicator
          ? null
          : CustomDropdownIndicator,
        ValueContainer: CustomValueContainer,
        Placeholder: CustomPlaceholder,
      },
      className: cn(styles.base, styles[kind], {
        [styles[size]]: kind !== 'transparent',
      }),
      classNamePrefix: 'react-select',
    };

    return (
      <div
        className={cn(
          { 'flex items-center': labelPosition === 'external-inline' },
          className
        )}
      >
        {(labelPosition === 'external' ||
          labelPosition == 'external-inline') && (
          <label
            id={`react-select-${uid}-label`}
            htmlFor={`react-select-${uid}-input`}
            className={cn('font-normal text-sm whitespace-nowrap', {
              'mr-4 font-semibold': labelPosition === 'external-inline',
              'hidden px-0.5 mb-1 lg:block': kind === 'transparent',
            })}
          >
            {label}
          </label>
        )}

        {async ? (
          <AsyncReactSelect<SelectFieldOption>
            ref={ref}
            {...commonProps}
            {...props}
          />
        ) : (
          <ReactSelect<SelectFieldOption>
            ref={ref}
            {...commonProps}
            {...props}
          />
        )}
      </div>
    );
  }
);

export default SelectField;
