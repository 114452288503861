import React, { memo } from 'react';
import cn from 'classnames';

import Icon from '../icon/Icon';
import TransparentButton from '../transparent-button/TransparentButton';
import { LayoutMode } from '../../../types/common';

import styles from './LayoutModeSwitch.module.scss';

export type LayoutModeSwitchProps = {
  currentMode: LayoutMode;
  onChange: (mode: LayoutMode) => void;
};

const LayoutModeSwitch = memo<LayoutModeSwitchProps>(
  ({ currentMode, onChange }) => (
    <div className="flex">
      <TransparentButton
        dataTestId={
          currentMode === 'grid'
            ? 'LayoutModeSwitch-grid-active'
            : 'LayoutModeSwitch-grid'
        }
        className={cn('mr-2 p-1.5', styles.switchButtonWrapper, {
          [styles.active]: currentMode === 'grid',
        })}
        onClick={() => {
          onChange('grid');
        }}
      >
        <Icon
          icon={currentMode === 'grid' ? 'view-cards-active' : 'view-cards'}
          alt="Card view"
          className="w-4"
        />
        <label
          className={cn(styles.label, {
            [styles.active]: currentMode === 'grid',
          })}
        >
          Grid view
        </label>
      </TransparentButton>
      <TransparentButton
        dataTestId={
          currentMode === 'list'
            ? 'LayoutModeSwitch-list-active'
            : 'LayoutModeSwitch-list'
        }
        className={cn(styles.switchButtonWrapper, {
          [styles.active]: currentMode === 'list',
        })}
        onClick={() => {
          onChange('list');
        }}
      >
        <Icon
          icon={currentMode === 'list' ? 'view-list-active' : 'view-list'}
          alt="List view"
          className="w-4"
        />
        <label
          className={cn(styles.label, {
            [styles.active]: currentMode === 'list',
          })}
        >
          List view
        </label>
      </TransparentButton>
    </div>
  )
);

export default LayoutModeSwitch;
