import { GetServerSidePropsContext } from 'next';
import axios from 'axios';

import { API_URL } from '../helpers/envs';
import { isAccessTokenExpired, signOutUser } from '../helpers/auth';
import {
  destroyAccessTokenCookie,
  getAccessTokenCookie,
} from '../helpers/cookies';

export const API = axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
});

API.interceptors.request.use(function (config) {
  const accessToken = config.headers['Authorization'];

  // TODO: Implement silent token refresh
  if (accessToken !== undefined && isAccessTokenExpired(accessToken)) {
    destroyAccessTokenCookie();
    signOutUser(true);
  }

  return config;
});

export const composeDefaultHeaders = (context?: GetServerSidePropsContext) => {
  const accessToken = getAccessTokenCookie(context);

  if (accessToken !== undefined) {
    return {
      Authorization: 'Bearer ' + accessToken,
    };
  }
};
