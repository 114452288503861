import React from 'react';
import cn from 'classnames';

export type ErrorMessageProps = {
  kind: 'info' | 'error' | 'warning' | 'success';
  message: string;
};

const StatusMessage = ({ kind, message }: ErrorMessageProps) => {
  return (
    <div
      className={cn('px-5 py-2 rounded-md shadow-sm', {
        'text-info bg-info-light': kind === 'info',
        'text-error bg-error-light': kind === 'error',
        'text-warning bg-warning-light': kind === 'warning',
        'text-success bg-success-light': kind === 'success',
      })}
      data-testid="ErrorMessage-wrapper"
    >
      {message}
    </div>
  );
};

export default StatusMessage;
