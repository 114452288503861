import { createContext, useContext } from 'react';
import { AuthContextValue } from '../types/auth';

export const AUTH_CONTEXT_DEFAULTS = {
  isAuthenticated: false,
  accessToken: null,
  userProfile: null,
  signOut: () => null,
};

export const AuthContext = createContext<AuthContextValue>(
  AUTH_CONTEXT_DEFAULTS
);

export const AuthContextProvider = AuthContext.Provider;

export const useAuth = () =>
  useContext<AuthContextValue>(AuthContext) ?? AUTH_CONTEXT_DEFAULTS;
